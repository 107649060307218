(function( $ ){
  $.fn.fluxxMountReactComponent = function(pageArea) {
    var reactNodes = $(pageArea).find(".flr-node");
    if (!reactNodes.length) return null;

    window.fluxxReact.isLoaded.done(function () {
      reactNodes.each(function () {
        var $element = $(this);
        var dataAttrs = $element.data();
        // Because of lazy loading, it is possible for this function to fire more than once
        if (dataAttrs.reactComponentRendered) return;

        window.fluxxReact.renderComponent({
          reactComponent: dataAttrs.react_component,
          rootNodeRef: $element[0],
          initialProps: dataAttrs,
          rootElement: $element
        });

        $element.data("reactComponentRendered", true)

        $.fn.attachReactUnmountListener($element);
      });
    });
  }

  $.fn.attachReactUnmountListener = function($element) {
    // React does not automatically unmount root level nodes when removed from
    // the DOM - so we must handle cleanup ourselves to prevent memory leaks
    $element.on("remove", function () {
      window.fluxxReact.unmountComponentAtNode($element[0]);
    });
  }

})(jQuery);